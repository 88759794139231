<template>
  <div>
    <div v-if="showAddtoWatchlist">
      <svg
        @click.stop="watchListHander"
        id="watchlist_add"
        data-name="watchlist add"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 27 27"
      >
        <g
          id="Ellipse_5"
          data-name="Ellipse 5"
          transform="translate(27 27) rotate(180)"
          stroke="#EA00FF"
          stroke-width="1"
        >
          <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
          <circle cx="13.5" cy="13.5" r="13" fill="#161616" />
        </g>
        <path
          id="Union_4"
          data-name="Union 4"
          d="M2.085,13.266A2.085,2.085,0,0,1,0,11.181v-9.1A2.085,2.085,0,0,1,2.085,0h9.1a2.085,2.085,0,0,1,2.085,2.085v9.1a2.085,2.085,0,0,1-2.085,2.085ZM1.9,2.085v9.1a.19.19,0,0,0,.19.19h9.1a.19.19,0,0,0,.19-.19v-9.1a.19.19,0,0,0-.19-.19h-9.1a.19.19,0,0,0-.189.19Zm3.98,7.2v-1.9H3.98a.758.758,0,1,1,0-1.516h1.9V3.98a.758.758,0,1,1,1.516,0v1.9h1.9a.758.758,0,1,1,0,1.516h-1.9v1.9a.758.758,0,1,1-1.516,0Z"
          transform="translate(6.867 6.867)"
          fill="#fff"
        />
      </svg>
    </div>
    <div v-if="!showAddtoWatchlist">
      <svg
        @click.stop="watchListHander"
        id="watchlist_remove"
        data-name="watchlist remove"
        xmlns="http://www.w3.org/2000/svg"
        width="27"
        height="27"
        viewBox="0 0 27 27"
      >
        <g
          id="Ellipse_5"
          data-name="Ellipse 5"
          transform="translate(27 27) rotate(180)"
          stroke="#EA00FF"
          stroke-width="1"
        >
          <circle cx="13.5" cy="13.5" r="13.5" stroke="none" />
          <circle cx="13.5" cy="13.5" r="13" fill="#EA00FF" />
        </g>
        <path
          id="Union_4"
          data-name="Union 4"
          d="M2.085,13.266A2.085,2.085,0,0,1,0,11.181v-9.1A2.085,2.085,0,0,1,2.085,0h9.1a2.085,2.085,0,0,1,2.085,2.085v9.1a2.085,2.085,0,0,1-2.085,2.085ZM1.9,2.085v9.1a.19.19,0,0,0,.19.19h9.1a.19.19,0,0,0,.19-.19v-9.1a.19.19,0,0,0-.19-.19h-9.1a.19.19,0,0,0-.189.19Zm3.98,5.306H3.98a.758.758,0,1,1,0-1.516H9.286a.758.758,0,1,1,0,1.516H5.875Z"
          transform="translate(6.867 6.867)"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import FirebaseActions from "@/mixins/FirebaseActions";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { _projectName } from "@/provider-config.js";
import { showSnackBar } from "../../utilities";
export default {
  props: {
    content: {
      type: Object,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    screen: {
      type: Object,
    },
  },
  data() {
    return {
      showAddtoWatchlist: true,
      action: null,
      fillColor: null,
      colorCodeActive: "#EA00FF",
      colorCodeDeactive: "#FFFFFF",
      BackCodeActive: "#EA00FF",
      BackCodeDeactive: null,
      watchlistFlag: false,
    };
  },
  watch: {
    content() {
      this.fetchButtonState();
    },
    subscriberid() {
      if (this.subscriberid) {
        this.fetchButtonState();
      }
    },
  },
  computed: {
    ...mapGetters(["subscriberid", "profileid"]),
  },
  created() {
    this.fetchButtonState();
    this.fillColor =
      _projectName === "VLIVE"
        ? "#FF9600"
        : _projectName === "NET5"
        ? "#FF9600"
        : _projectName === "NAMMAFLIX"
        ? "#ffffff"
        : _projectName === "Noor Play"
        ? "#EA00FF"
        : "";
  },
  methods: {
    watchListHander() {
      if (this.subscriberid) {
        this.watchlistFlag = !this.watchlistFlag;
        if (this.showAddtoWatchlist) {
          this.addToWatchList();
          showSnackBar(this.$t("Added to library"), this.$t("DISMISS"));
        } else {
          showSnackBar(this.$t("removed from library"), this.$t("DISMISS"));

          this.removeFromWatchlist();
        }
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    fetchButtonState() {
      if (!this.subscriberid) {
        this.showAddtoWatchlist = true;
        return;
      }
      if (!this.content) return;

      let content = this.content;

      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      if ("seriesid" in content && content.seriesid) {
        // let seriesid = content.seriesid;
        path += `${content.objectid}/episodes/${content.objectid}`;
      } else {
        path += `${content.objectid}`;
      }
      firebase
        .database()
        .ref(path)
        .on("value", (snapshot) => {
          let response = snapshot.val();
          //   console.log("snapshot", snapshot.val());
          if (response && response.inwatchlist == true) {
            this.showAddtoWatchlist = false;
            this.action = "REMOVE";
          } else if (response && response.inwatchlist == false) {
            this.showAddtoWatchlist = true;
            this.action = "ADD";
          } else {
            this.showAddtoWatchlist = true;
            this.action = "ADD";
          }
          //   console.log("watchlist action", this.action);
        });
    },
    addToWatchList() {
      if (this.subscriberid) {
        this.showAddtoWatchlist = false;
        // this.content.orientation = this.screen.displayType;
        this.updateContentWatchlist(this.content, true);
        // if (this.action == "ADD") {
        //   console.log("update with add");
        //   this.updateContentWatchlist(this.content, true);
        // } else if (this.action == "REMOVE") {
        //   console.log("update with remove");
        //   this.updateContentWatchlist(this.content, false);
        // }

        //logging watchlist google analytics event.
        let GA = {
          action_name: "Add to watchlist",
        };
        this.userActionEvent(GA);
      } else {
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    removeFromWatchlist() {
      if (this.subscriberid) {
        this.showAddtoWatchlist = true;
        this.updateContentWatchlist(this.content, false);

        //logging watchlist google analytics event.
        let GA = {
          action_name: "Remove from watchlist",
        };
        this.userActionEvent(GA);
      }
    },
  },
  mixins: [FirebaseActions, googleAnalytics],
};
</script>

<style>
@import "./watchListBtn.scss";
</style>
